import { NavigationGuard } from "vue-router";
import auth from "@/utils/auth";
import Vue from "vue";
import { MetaPermission } from "@/data/default/types/metaPermission";

export const PermissionGuard: NavigationGuard = (to, from, next) => {
  const findPermissionBase = to.matched.find((a: any) => a.meta.permission);
  if (!findPermissionBase) {
    next();
    return;
  }

  const permission = findPermissionBase.meta.permission as MetaPermission;
  if (permission && permission.action && permission.entity) {
    const hasPermission = auth.hasPermission(
      permission.entity,
      permission.action
    );

    if (!hasPermission) {
      Vue.notify({
        title: "Segurança",
        type: "error",
        text: "Você não tem permissões suficientes para entrar na página."
      });
      auth.authClearAll();
      next("/login");
      return;
    }
  }

  next();
};
