import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import session from "./modules/session";
import ativacaoEmail from "./modules/ativacaoEmail";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["session"]
});

export default new Vuex.Store<any>({
  modules: {
    session,
    ativacaoEmail
  },
  plugins: [vuexLocal.plugin]
});
