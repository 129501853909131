import Vue from "vue";
import ButtonLoader from "@/components/ButtonLoader.vue";
import ContentLoader from "@/components/ContentLoader.vue";
import Filterbar from "@/components/Filter/Filterbar.vue";
import ActionbarList from "@/components/ActionbarList.vue";
import ActionbarSave from "@/components/ActionbarSave.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormCheckbox from "@/components/Form/FormCheckbox.vue";
import FormMask from "@/components/Form/FormMask.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormAutocomplete from "@/components/Form/FormAutocomplete.vue";
import FormAutocompleteCustom from "@/components/Form/FormAutocompleteCustom.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormUploadFile from "@/components/Form/FormUploadFile.vue";
import InlineCounters from "@/components/InlineCounters/InlineCounters.vue";

import DebugTag from "@/components/DebugTag.vue";
import Password from "vue-password-strength-meter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { TheMask } from "vue-the-mask";
import Empty from "@/components/Empty.vue";
import ModalWrapper from "./ModalWrapper";
import FormTextarea from "./Form/FormTextarea.vue";
import { Table } from "./Table";
import FormTransfer from "./Form/FormTransfer.vue";
import JsonDebug from "./JsonDebug.vue";

Vue.component("button-loader", ButtonLoader);
Vue.component("content-loader", ContentLoader);
Vue.component("filterbar", Filterbar);
Vue.component("empty", Empty);
Vue.component("debug-tag", DebugTag);
Vue.component("modal-wrapper", ModalWrapper);

Vue.component("actionbar-list", ActionbarList);
Vue.component("actionbar-save", ActionbarSave);

Vue.component("app-form-input", FormInput);
Vue.component("app-form-checkbox", FormCheckbox);
Vue.component("app-form-mask", FormMask);
Vue.component("app-form-select", FormSelect);
Vue.component("app-form-autocomplete", FormAutocomplete);
Vue.component("app-form-autocomplete-custom", FormAutocompleteCustom);
Vue.component("app-form-date", FormDate);
Vue.component("app-form-textarea", FormTextarea);
Vue.component("app-table", Table);
Vue.component("app-form-transfer", FormTransfer);
Vue.component("app-form-toggle", FormToggle);
Vue.component("app-form-upload-file", FormUploadFile);
Vue.component("app-inline-counters", InlineCounters);

Vue.component("password", Password);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("the-mask", TheMask);
Vue.component("app-json-debug", JsonDebug);
