import { Mapper } from "./mapper";
import { Midia } from "@/data/default/types/midia";

export class MidiaMapper extends Mapper<Midia> {
  public fromSource(model: any): Midia | null {
    if (!model) {
      return new Midia();
    }

    return new Midia({
      publicUrl: model.publicUrl,
      selector: model.selector,
      name: model.name
    });
  }

  public toSource(model: Midia) {
    return {
      name: model.name,
      base64: model.base64,
      selector: model.selector
    };
  }
}
