















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ContentLoader extends Vue {
  @Prop({ required: true, type: Boolean }) public busy!: boolean;
}
