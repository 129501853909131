export enum FileUploadPreviewStatus {
  SUCCESS,
  ERROR,
  IDLE,
  LOADING
}

export class FileUploadPreview {
  public id: number;
  public file: File;
  public status: FileUploadPreviewStatus;
  public payload: any = {};
  public preview!: string;

  constructor(file: File) {
    this.id = Math.random();
    this.file = file;
    this.status = FileUploadPreviewStatus.LOADING;
    this.getBase64().then(r => {
      this.preview = r;
      this.status = FileUploadPreviewStatus.IDLE;
    });
  }

  public isImage() {
    return this.file.type.match(/image\/.*/g);
  }

  public isVideo() {
    return this.file.type.match(/video|audio\/.*/g);
  }

  public setStatus(status: FileUploadPreviewStatus): void {
    this.status = status;
  }

  public getBase64(): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.readAsDataURL(this.file);
    });
  }

  public statusSuccess(): boolean {
    return this.status === FileUploadPreviewStatus.SUCCESS;
  }
  public statusError(): boolean {
    return this.status === FileUploadPreviewStatus.ERROR;
  }
  public statusIdle(): boolean {
    return this.status === FileUploadPreviewStatus.IDLE;
  }
  public statusLoading(): boolean {
    return this.status === FileUploadPreviewStatus.LOADING;
  }

  public getSizeMb(): number {
    return this.file.size / 1024 / 1024;
  }

  public getSizeView() {
    return this.getSizePreview(this.file.size);
  }

  private getSizePreview(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
