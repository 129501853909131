import { RouteConfig } from "vue-router";
import { PermissionGuard } from "@/shared/guards/permission";
import { TypePermissionEntity, TypePermissionAction } from "./enumPermission";
import { MetaPermission } from "./metaPermission";
import Vue, { AsyncComponent, ComponentOptions } from "vue";

export type Component = ComponentOptions<Vue> | typeof Vue | AsyncComponent;

export interface RootRouteParams {
  component: Component;
  redirectTo?: string | null;
}

export default class RouterBuilder {
  private rootRoute?: RouteConfig;
  private routes: RouteConfig[] = [];
  private baseName?: string;
  private typePermissionEntity?: TypePermissionEntity;
  private readonly ROUTER_LIST_NAME: string = "List";
  private readonly ROUTER_SAVE_NAME: string = "Save";

  public withTypePermissionEntity(
    typePermissionEntity: TypePermissionEntity
  ): this {
    this.typePermissionEntity = typePermissionEntity;
    return this;
  }

  public withBaseName(name: string): this {
    this.baseName = name;
    return this;
  }

  public withRouteRoot({
    component,
    redirectTo = "listagem"
  }: RootRouteParams): this {
    if (!this.typePermissionEntity) {
      throw new Error("Need a type permission entity to continue");
    }

    if (!this.baseName) {
      throw new Error("Need a baseName to continue");
    }

    const permission: MetaPermission = {
      action: TypePermissionAction.SCREEN,
      entity: this.typePermissionEntity
    };

    this.rootRoute = {
      path: this.baseName,
      name: this.baseName,
      component,
      meta: { permission, baseName: this.baseName },
      beforeEnter: PermissionGuard,
      children: []
    };

    if (redirectTo) {
      this.rootRoute.redirect = `${this.baseName}/${redirectTo}`;
    }

    return this;
  }

  public getRouteListName(): string {
    return this.baseName + this.ROUTER_LIST_NAME;
  }

  public getRouteSaveName(): string {
    return this.baseName + this.ROUTER_SAVE_NAME;
  }

  public withRouteList({ component }: any): this {
    if (!this.typePermissionEntity) {
      throw new Error("Need a type permission entity to continue");
    }

    const permission: MetaPermission = {
      action: TypePermissionAction.LIST_PAGINATION,
      entity: this.typePermissionEntity
    };

    this.setRoute({
      path: "listagem",
      name: this.getRouteListName(),
      meta: { permission, baseName: this.baseName },
      beforeEnter: PermissionGuard,
      component
    });

    return this;
  }

  public withRouteSave({ component }: any): this {
    if (!this.typePermissionEntity) {
      throw new Error("Need a type permission entity to continue");
    }

    const permission: MetaPermission = {
      action: TypePermissionAction.CREATE,
      entity: this.typePermissionEntity
    };

    this.setRoute({
      path: "salvar/:id?",
      name: this.getRouteSaveName(),
      meta: { permission, baseName: this.baseName },
      beforeEnter: PermissionGuard,
      component
    });

    return this;
  }

  public getRootRoute(): RouteConfig {
    if (!this.rootRoute) {
      throw new Error("Need a root route to get this");
    }

    return this.rootRoute;
  }

  public getRouteNames(): { [key: string]: string } {
    return {
      listagem: this.getRouteListName(),
      salvar: this.getRouteSaveName()
    };
  }

  public build(): this {
    if (!this.rootRoute) {
      throw new Error("Need a root route on builder to create the route");
    }

    this.addRoutesOnRootChildren();
    return this;
  }

  private setRoute(route: RouteConfig): this {
    this.routes.push(route);
    return this;
  }

  private addRoutesOnRootChildren(): this {
    if (!this.rootRoute) {
      return this;
    }

    this.routes.forEach(route => {
      this.rootRoute!.children!.push(route);
    });

    return this;
  }
}
