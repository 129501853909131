import { AdminGuard } from "@/shared/guards/admin";
import { RouteConfig } from "vue-router";
import RouterManager from "@/utils/routerManager";

const getAllScreensRouters = (): RouteConfig[] => {
  const webpackRequired = require.context(
    "../../",
    true,
    /screens[\/\\](default)[\/\\]\w+\/router$/
  );
  return webpackRequired
    .keys()
    .map(path => webpackRequired(path).Router)
    .reduce((ant, curr) => ant.concat(...curr), []);
};

const mainRouter = new RouterManager();
mainRouter
  .addPublicRouteList([
    {
      path: "",
      redirect: "/login"
    },
    {
      path: "/politica-privacidade",
      name: "politica-privacidade",
      component: () => import("@/screens/default/PoliticaPrivacidade.vue")
    },
    {
      path: "/ativacao/:token?",
      name: "ativacao-email",
      component: () => import("@/screens/default/AtivacaoEmail.vue")
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/screens/default/login/Login.vue")
    }
  ])
  .setAdminWrapper({
    path: "/admin",
    name: "admin",
    component: () => import("@/screens/default/Layout.vue"),
    beforeEnter: AdminGuard
  })
  .addAdminRoute({
    path: "home",
    name: "home",
    component: () => import("@/screens/default/Home.vue")
  })
  .addAdminRouteList(getAllScreensRouters())
  .setRedirect({
    path: "**",
    redirect: "/login"
  });

export default mainRouter;
