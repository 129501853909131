





















import { Component, Prop } from "vue-property-decorator";
import { FormMixin } from "./formMixin";
import { TheMask } from "vue-the-mask";
import Vue from "vue";
import constants from "@/config/default/constants";

@Component({
  mixins: [FormMixin],
  components: {
    TheMask
  }
})
export default class FormGroup extends Vue {
  @Prop({ default: "", type: [Array, String] }) public mask!: string[];
  @Prop({ default: false }) public raw!: boolean;
  @Prop({ default: "text" }) public type!: string;
  @Prop({ default: false, type: Boolean }) public isCpf!: boolean;
  @Prop({ default: false, type: Boolean }) public isPhone!: boolean;

  get finalMask() {
    if (this.isCpf) return constants.MASKS.CPF;
    if (this.isPhone) return constants.MASKS.PHONE;

    return this.mask;
  }
}
