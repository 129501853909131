<template>
  <json-viewer :value="value" :expand-depth="5" copyable boxed sort />
</template>

<script>
import JsonViewer from "vue-json-viewer";

export default {
  components: { JsonViewer },
  props: {
    value: {
      required: true,
      type: [Object, Array]
    }
  }
};
</script>
