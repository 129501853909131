import Vue, { CreateElement } from "vue";
import { BModal } from "bootstrap-vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "lg",
      validator: (x: string) => ["sm", "lg", "md", "xl"].includes(x)
    },
    id: {
      type: String,
      required: true
    }
  },
  render(h: CreateElement) {
    const getModalFooter = () => this.$scopedSlots["modal-footer"];
    const hasModalFooter = (): boolean => "modal-footer" in this.$scopedSlots;

    return h(
      BModal,
      {
        on: {
          close: (event: Event) => {
            this.$emit("close", event);
          },
          shown: (event: Event) => {
            this.$emit("shown", event);
          }
        },
        props: {
          id: this.id,
          title: this.title,
          centered: true,
          size: this.size,
          "hide-footer": !hasModalFooter(),
          ...this.$attrs
        },
        scopedSlots: {
          "modal-footer"(props) {
            const modalFooter = getModalFooter();
            if (modalFooter) {
              return modalFooter(props);
            }
          }
        }
      },
      this.$slots.default
    );
  }
});
