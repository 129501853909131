







































import Vue from "vue";
import FileUploadPreviewComponent from "./FilePreview.vue";
import { FileUploadPreview } from "./FileUploadPreview";

export default Vue.extend({
  components: {
    FileUploadPreview: FileUploadPreviewComponent
  },
  props: {
    acceptedMimes: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxSizeMb: {
      type: Number,
      default: 100
    }
  },
  data: () => ({
    files: [] as FileUploadPreview[],
    isDropOver: false
  }),
  mounted() {
    this.listenChangeInput();
  },
  methods: {
    listenChangeInput() {
      const findInput = this.$refs["file-input"] as any;
      findInput.addEventListener("change", (ev: any) => {
        this.filesToList(ev.target.files);
      });
    },
    getAcceptedFiles() {
      return this.files;
    },
    dropHandler(ev: any) {
      ev.stopPropagation();
      ev.preventDefault();

      const files = ev.dataTransfer.files as File[];
      const filesLength = files.length;
      const internalFileLength = this.files.length + filesLength;

      if (!this.multiple && internalFileLength > 1) {
        this.$notify({
          title: "Quantidade de arquivos",
          text: "Deve-se colocar apenas um arquivo por vez",
          type: "info"
        });

        this.$emit("filled");
        this.isDropOver = false;
        return;
      }
      this.filesToList(files);

      this.isDropOver = false;
    },
    isAcceptedMime(file: File): boolean {
      if (!this.acceptedMimes) {
        return true;
      }

      const acceptedFiles = this.acceptedMimes.split(",");
      const mimeType = file.type;
      let accepted = false;

      for (const mime of acceptedFiles) {
        accepted = !!mime.match(mimeType);
      }

      return accepted;
    },
    filesToList(files: File[]) {
      for (const file of files) {
        if (this.isAcceptedMime(file)) {
          const filePreview = new FileUploadPreview(file);

          if (filePreview.getSizeMb() > this.maxSizeMb) {
            this.$notify({
              title: "Tamanho do arquivo",
              text: "O arquivo enviado é muito grande",
              type: "error"
            });

            this.$emit("size-error", file);
          } else {
            this.files.push(filePreview);
            this.$emit("change", this.files);
          }
        } else {
          this.$notify({
            title: "Tipo de arquivo",
            text: "O arquivo enviado não é do tipo solicitado",
            type: "error"
          });

          this.$emit("mime-error", file);
        }
      }
    },
    dropOverHandler() {
      this.isDropOver = true;
    },
    dropLeaveHandler() {
      this.isDropOver = false;
    },
    handlerClick() {
      this.openFinder();
    },
    openFinder() {
      const findInput = this.$refs["file-input"] as any;
      findInput.click();
    },
    clear() {
      this.files = [];
      this.$emit("change", this.files);
    },
    handleDelete(file: FileUploadPreview) {
      this.files = this.files.filter(f => f !== file);
      this.$emit("change", this.files);
    }
  }
});
