import { Base } from "./base";
import { Dispositivo } from "./dispositivo";
import { Perfil } from "./perfil";
import { AreaDespacho } from "./areaDespacho";
import { CentralAtendimento } from "./centralAtendimento";

export abstract class Usuario extends Base {
  public senha?: string;
  public confirmarSenha?: string;
  public email?: string;
  public username?: string;
  public dispositivoAtivo?: Dispositivo;
  public enabled?: boolean;
  public areasDespacho?: AreaDespacho[] | string[];
  public areaDespachoUsuario?: AreaDespacho | string;
  public perfil?: Perfil | string;
  public centralAtendimento?: CentralAtendimento | string;
}

export interface UsuarioLogin extends Base {
  usuario: string;
  senha: string;
}
