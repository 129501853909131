import { TypePermissionEntity } from "@/data/default/types/enumPermission";
import { RouteConfig } from "vue-router";
import RouterDirector from "@/data/default/types/RouterDirector";

const builder = RouterDirector.makeRouterWithPermission({
  name: "medidaProtetivaInfo",
  permissionEntity: TypePermissionEntity.MEDIDA_PROTETIVA_INFO,
  componentRoot: () => import("@/screens/ro/medidaProtetivaInfo/Index.vue"),
  componentSave: () => import("@/screens/ro/medidaProtetivaInfo/Save.vue"),
  redirectTo: "salvar"
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
