import Request from "@/data/default/api/index";
import { AxiosInstance } from "axios";
import CustomResponse, {
  CustomResponsePagination,
  StatusResponse
} from "@/data/default/types/response";
import { Mapper } from "@/data/default/mappers/mapper";
import { getOnlyPropWithValues } from "@/utils";

export default class ServiceApi<T> {
  public route: string;
  public request: AxiosInstance = Request;
  public mapper: Mapper<T> | null;

  constructor({ route, mapper }: { route: string; mapper: Mapper<T> | null }) {
    this.route = route;
    this.mapper = mapper;
  }

  get fullRoute() {
    return process.env.VUE_APP_API_URL + "/" + this.route;
  }

  public create(params: any): Promise<CustomResponse<T>> {
    params = this.toSource(params);
    return this.request.post(this.route, params);
  }

  /**
   *
   * @param custom PaginationResponse
   */
  public _mapLinksPaginationToNumber(
    custom: CustomResponsePagination<T>
  ): CustomResponsePagination<T> {
    if (!(custom && custom.params)) {
      return custom;
    }
    const first: any = custom.params.links.first.toString().match(/(\d+)/g);
    const last: any = custom.params.links.last.toString().match(/(\d+)/g);
    const self: any = custom.params.links.self.toString().match(/(\d+)/g);
    custom.params.links.first = parseInt(first[0]);
    custom.params.links.last = parseInt(last[0]);
    custom.params.links.self = parseInt(self[0]);

    return custom;
  }

  public edit(selector: string, params: T): Promise<CustomResponse<any>> {
    params = this.toSource(params);
    return this.request.put(`${this.route}/${selector}`, params);
  }

  public getAll(page = 1, filter = {}): Promise<CustomResponsePagination<T>> {
    return this.request
      .get<T, CustomResponsePagination<T>>(
        `${this.route}/pagination?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      })
      .catch(this.catchRequisitionPagination);
  }

  public getAllWithoutPagination(): Promise<CustomResponse<T[]>> {
    return this.request
      .get<T, CustomResponse<T[]>>(`${this.route}`)
      .then(response => {
        response.params = this.fromSourceList(response.params);
        return response;
      })
      .catch(this.catchRequisition);
  }

  public getAllByStatus(
    status: string,
    page: number,
    filter: Record<string, string> = {}
  ) {
    return this.request
      .get<T, CustomResponsePagination<T>>(
        `${this.route}/${status}?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      })
      .catch(this.catchRequisitionPagination);
  }

  public getUnique(): Promise<CustomResponse<T | null>> {
    return this.request
      .get<T, CustomResponse<T | null>>(`${this.route}`)
      .then(response => {
        response.params = response.params
          ? this.fromSource(response.params)
          : null;
        return response;
      })
      .catch(this.catchRequisition);
  }

  public catchRequisition(): CustomResponse<any> {
    return {
      params: null,
      message: "",
      status: StatusResponse.FAIL
    };
  }

  public catchRequisitionPagination(): CustomResponsePagination<any> {
    return {
      message: "",
      params: {
        count: 0,
        counters: {},
        items: [],
        links: {
          first: 1,
          last: 1,
          self: 1
        },
        maxPerPage: 0,
        total: 0
      },
      status: "fail"
    };
  }

  public fromSourceList(items: T[]): T[] {
    if (this.mapper === null) {
      return items;
    }

    return this.mapper!.fromSourceList(items);
  }

  public fromSource(params: T): T | null {
    if (!this.mapper) {
      return params;
    }

    return this.mapper!.fromSource(params);
  }

  public toSource(params: T): any {
    if (!this.mapper) {
      return params;
    }

    return this.mapper!.toSource(params);
  }

  public getOne(selector: string): Promise<CustomResponse<T>> {
    return this.request
      .get<T, CustomResponse<T>>(`${this.route}/${selector}`)
      .then(response => {
        response.params = this.fromSource(response.params) as T;
        return response;
      });
  }

  public remove(selector: string): Promise<CustomResponse<T>> {
    return this.request.delete(`${this.route}/${selector}`);
  }

  public getAllCounters(filter): Promise<any> {
    return this.request.get(`${this.route}/total`, {
      params: getOnlyPropWithValues(filter)
    });
  }
}
