





































import { createComponent } from "@vue/composition-api";
import Fileupload from "@/components/Fileupload/Fileupload.vue";
import { InvalidFeedback } from "./InvalidFeedback";
import { sanitizeBase64 } from "@/utils";
import { Midia } from "@/data/default/types/midia";
import { FormMixin } from "./formMixin";
import useDeleteModal from "@/shared/compositions/useDeleteModal";

interface Props {
  value: Record<string, any>;
  acceptedMimes: string;
  multiple: boolean;
}

export default createComponent({
  components: {
    Fileupload,
    InvalidFeedback
  },
  mixins: [FormMixin],
  props: {
    acceptedMimes: {
      type: String,
      default: ""
    },
    // TODO: não implementei tudo referente ao multiple mas mantive aqui pra facilitar
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props: Props, { emit }) {
    const deleteModal = useDeleteModal();

    const handlerChange = async files => {
      const filesMidia: Midia[] = [];
      for (const filePreview of files) {
        const base64 = await filePreview.getBase64();

        filesMidia.push(
          new Midia({
            name: filePreview.file.name,
            base64: sanitizeBase64(base64)
          })
        );
      }

      if (filesMidia.length) {
        emit("input", props.multiple ? filesMidia : filesMidia[0]);
      } else {
        emit("input", props.multiple ? [] : null);
      }
    };

    const handleRemoveFile = () => {
      deleteModal.open(confirmed => {
        confirmed && emit("input", null);
      });
    };

    return { handlerChange, handleRemoveFile };
  }
});
