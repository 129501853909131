













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ButtonLoader extends Vue {
  @Prop({ required: true, type: Boolean }) public busy!: boolean;
  @Prop({ required: true, type: String }) public text!: string;
  @Prop({ default: "dark", type: String }) public variant!: string;
  @Prop({ type: String }) public size!: string;
  @Prop({ type: String, default: "submit" }) public type!: string;
  @Prop({ type: Boolean, default: false }) public disabled!: string;

  public handlerClick() {
    this.$emit("click");
  }
}
