import RouterBuilder, { Component } from "./RouterBuilder";
import { TypePermissionEntity } from "./enumPermission";

export interface MakeCompleteParams {
  name: string;
  permissionEntity: TypePermissionEntity;
  componentRoot: Component;
  componentList?: Component;
  componentSave?: Component;
  redirectTo?: string | null;
}

export default class RouterDirector {
  public static makeRouterWithPermission({
    name,
    permissionEntity,
    componentRoot,
    componentList,
    componentSave,
    redirectTo
  }: MakeCompleteParams): RouterBuilder {
    const builder = new RouterBuilder()
      .withBaseName(name)
      .withTypePermissionEntity(permissionEntity)
      .withRouteRoot({
        component: componentRoot,
        redirectTo: componentList ? "listagem" : redirectTo
      });

    if (componentList) {
      builder.withRouteList({
        component: componentList
      });
    }
    if (componentSave) {
      builder.withRouteSave({
        component: componentSave
      });
    }
    return builder.build();
  }
}
