import { Base } from "./base";

export class Midia extends Base {
  public name?: string;
  public base64?: string;
  public publicUrl?: string;

  constructor({ name, base64, publicUrl, selector }: Midia = {}) {
    super({ selector });
    this.name = name;
    this.base64 = base64 || "";
    this.publicUrl = publicUrl || "";
  }
}
