import { RouteConfig } from "vue-router";
import RouterDirector from "@/data/default/types/RouterDirector";
import { TypePermissionEntity } from "@/data/default/types/enumPermission";

const builder = RouterDirector.makeRouterWithPermission({
  name: "dispositivo",
  permissionEntity: TypePermissionEntity.DISPOSITIVOS,
  componentRoot: () => import("@/screens/default/dispositivos/Index.vue"),
  componentList: () => import("@/screens/default/dispositivos/List.vue")
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
