





















import Vue from "vue";
import { watch, ref, computed } from "@vue/composition-api";
import { InvalidFeedback } from "./InvalidFeedback";
import { FormMixin } from "./formMixin";

export default Vue.extend({
  components: {
    InvalidFeedback
  },
  mixins: [FormMixin],
  props: {
    options: {
      required: true,
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectSize: {
      type: Number,
      default: 0
    },
    showTotalSelecteds: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    }
  },
  setup(props: any, { emit }) {
    const localValue = ref<string | string[]>([]);

    watch(
      () => props.value,
      value => {
        if (props.multiple) {
          localValue.value = value ? value : [];
        } else {
          localValue.value = value ? value : null;
        }
      }
    );

    const handleChange = () => {
      emit("change", localValue.value);
    };

    const handleInput = () => {
      emit("input", localValue.value);
    };

    const totalSelected = computed(() =>
      localValue.value ? localValue.value.length : 0
    );
    return { handleChange, handleInput, localValue, totalSelected };
  }
});
