














import Vue from "vue";
import { InvalidFeedback } from "./InvalidFeedback";
import { FormMixin } from "./formMixin";
import { ToggleButton } from "vue-js-toggle-button";

export default Vue.extend({
  components: {
    InvalidFeedback,
    ToggleButton
  },
  mixins: [FormMixin],
  props: {}
});
