import { Sexo } from "./sexo";
import { Midia } from "./midia";
import { Endereco } from "./endereco";
import { Raca } from "./raca";
import { Base } from "./base";
import { Profissao } from "./profissao";
import { EnderecoPessoa } from "./enderecoPessoa";

export class Pessoa extends Base {
  public fotoPrincipal?: Midia;
  public enderecoPrincipal?: Endereco;
  public nome?: string;
  public rg?: string;
  public sexo?: Sexo | string;
  public cpf?: string;
  public medidaProtetiva?: boolean;
  public medidaProtetivaAtiva?: boolean;
  public nomeMae?: string;
  public numeroCelular?: string;
  public dataNascimento?: string;
  public racaCor?: Raca;
  public profissao?: Profissao;
  public botaoPanicoAtivado?: boolean;
  public enderecos!: EnderecoPessoa[];

  constructor() {
    super();
    this.nome = "";
    this.nomeMae = "";
    this.rg = "";
    this.cpf = "";
    this.numeroCelular = "";
    this.dataNascimento = "";
    this.sexo = "";
  }
}
