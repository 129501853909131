import { ActionTree, MutationTree, GetterTree } from "vuex";
import { UsuarioLogin } from "@/data/default/types/usuario";
import usuarioAPI from "@/data/default/api/usuario";
import { Perfil } from "@/data/default/types/perfil";
import {
  JWTDecode,
  ResponseAutenticacao
} from "@/data/default/types/autenticacao";
import auth from "@/utils/auth";
import CustomResponse from "@/data/default/types/response";
import UsuarioMapper from "@/data/default/mappers/usuarioRetaguardaMapper";
import { UsuarioRetaguarda } from "@/data/default/types/usuarioRetaguarda";
import constants from "@/config/default/constants.ts";
import bus from "@/bus";
import { AxiosError } from "axios";
import { StatusResponse } from "@/data/default/types/response";

interface RootState {
  me?: UsuarioRetaguarda;
  perfil?: Perfil;
  username: string;
}

const state: RootState = {
  username: ""
};

const getters: GetterTree<any, RootState> = {
  perfilDescricao(state: RootState): string {
    return state.perfil?.descricao || "";
  },
  meSelector(state: RootState): string {
    return state.me?.selector || "";
  },
  username(state: RootState): string {
    return state.me?.username || "";
  },
  isEstadual(state: RootState): boolean {
    return state.perfil?.selector === constants.PERFIL.ESTADUAL;
  }
};

const actions: ActionTree<RootState, RootState> = {
  auth({ commit, dispatch }, form: UsuarioLogin) {
    return usuarioAPI
      .autenticacao(form)
      .then((data: CustomResponse<ResponseAutenticacao>) => {
        if (typeof data.token !== "string") {
          return false;
        }

        const usuarioMapper = new UsuarioMapper();
        commit("setUsuario", usuarioMapper.fromSource(data.params.usuario));
        dispatch("updateToken", data.token);
        return data;
      })
      .catch((err: AxiosError<CustomResponse<ResponseAutenticacao>>) => {
        if (err.response?.data.status == StatusResponse.WAITING_USER_ACTIVATE) {
          bus.$emit("waiting-user-activate");
        }
      });
  },
  updateToken({ dispatch }, token: string) {
    const tokenDecoded: JWTDecode = auth.decode<JWTDecode>(token);
    dispatch("setDecodedToken", tokenDecoded);
  },
  setDecodedToken({ commit }, token: JWTDecode) {
    commit("setPerfil", token.perfil);
  }
};

const mutations: MutationTree<RootState> = {
  setUsuario: (state, payload: UsuarioRetaguarda) => {
    state.me = payload;
  },
  setPerfil: (state, payload: Perfil) => {
    state.perfil = payload;
  }
};

const store = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

export default store;
