import { Sexo } from "./sexo";
import { Base } from "./base";
import { Unidade } from "./unidade";
import { Efetivo } from "./efetivo";

export class PessoaEfetivo extends Base {
  public sexo?: Sexo | string;
  public nome?: string;
  public cpf?: string;
  public telefone?: string;
  public dataNascimento?: string;
  public matricula?: number;
  public digito?: number;
  public unidade?: Unidade | string;
  public postoGraduacao?: string;
  public efetivo?: Efetivo | string;

  constructor() {
    super();
    this.sexo = "";
    this.nome = "";
    this.cpf = "";
    this.telefone = "";
    this.dataNascimento = "";
    this.matricula = undefined;
    this.digito = undefined;
    this.unidade = new Unidade();
    this.postoGraduacao = "";
    this.efetivo = "";
  }
}
