export enum TypePermissionEntity {
  DISPOSITIVOS = "dispositivo",
  OCORRENCIA = "ocorrencia",
  AVALIACAO_ELOGIO = "avaliacao_elogio",
  AVALIACAO_PERGUNTA = "avaliacao_pergunta",
  ENTIDADE_APOIO = "entidade_apoio",
  SOLICITACAO_VISITA_PREVENTIVA = "visita_preventiva",
  SOLICITACAO_MEDIDA_PROTETIVA = "solicitacao_medida_protetiva",
  MEDIDA_PROTETIVA = "medida_protetiva",
  TIPO_OCORRENCIA = "tipo_ocorrencia",
  SEXO = "sexo",
  RACA = "raca",
  TIPO_COMUNICANTE = "tipo_comunicante",
  TIPO_SUGESTAO = "tipo_sugestao",
  FORMA_VIOLENCIA = "forma_violencia",
  LEGISLACAO_VIGENTE = "legislacao_vigente",
  SUGESTAO = "sugestao",
  SOLICITACAO_REDE = "solicitacao_rede",
  PAIS = "pais",
  ESTADO = "estado",
  MUNICIPIO = "municipio",
  USUARIO_MOBILE = "usuario_mobile",
  USUARIO = "usuario",
  PERFIL_USUARIO = "perfil_usuario",
  PERFIL = "perfil",
  CENTRAL_ATENDIMENTO = "central_atendimento",
  AREA_DESPACHO = "area_despacho",
  DENUNCIA = "denuncia",
  FORMULARIO_FRIDA = "formulario_frida",
  REDE_VIZINHO = "rede_vizinho",
  ALERTAS = "alertas",
  BEM_MOVEL = "bem_movel",
  UNIDADE_CIDADAO = "unidade_cidadao",
  SOLICITACAO_CONSULTORIA = "solicitacao_consultoria",
  EVENTOS = "eventos",
  SOLICITACAO_EVENTO = "solicitacao_evento",
  ENQUETE = "enquete",
  AREA_DESPACHO_VIAGEM = "area_despacho_viagem",
  VIAGEM_SEGURA = "viagem_segura",
  DICAS_SEGURANCA = "dicas_seguranca",
  REDE_SOCIAL_OFICIAL = "rede_social_oficial",
  PROERD = "proerd",
  TIPO_VIOLENCIA_MULHER = "tipo_violencia_mulher",
  PATRULHA_MARIA_PENHA = "patrulha_maria_penha",
  MUSEU_VIRTUAL = "museu_virtual",
  POLICIA_MILITAR_MIRIM = "policia_militar_mirim",
  MEDIACAO_COMUNITARIA = "mediacao_comunitaria",
  DIREITO_COMO_CIDADAO = "direito_como_cidadao",
  ESTATISTICAS = "estatisticas",
  UNIDADE_MILITAR = "unidade_militar",
  REDE_ENFRENTAMENTO = "rede_enfrentamento",
  REDE_ATENDIMENTO = "rede_atendimento",
  MEDIDA_PROTETIVA_INFO = "medida_protetiva_info",
  DASHBOARD = "dashboard",
  // Used to create default routes with builder
  DISABLED_PERMISSION = "disabled_permission"
}

export enum TypePermissionAction {
  VIEW = "view",
  VIEW_DETAILS = "view_details",
  VIEW_FILE = "view_file",
  DELETE = "delete",
  CREATE = "create",
  EDIT = "edit",
  LIST_PAGINATION = "list_pagination",
  LIST_ALL = "list_all",
  CHANGE_PASSWORD = "change_password",
  LIST_PENDING = "list_pending",
  LIST_APPROVED = "list_approved",
  LIST_PRE_APPROVED = "list_pre_approved",
  LIST_DECLINED = "list_declined",
  LIST_SCHEDULED = "list_scheduled",
  LIST_ACTIVE = "list_active",
  LIST_INACTIVE = "list_inactive",
  LIST_PAST = "list_past",
  LIST_CANCELLED = "list_cancelled",
  LIST_CONFIRMED = "list_confirmed",
  LIST_RESOLVED = "list_resolved",
  LIST_UNRESOLVED = "list_unresolved",
  LIST_ARCHIVED = "list_archived",
  LIST_IN_PROGRESS = "list_in_progress",
  LIST_FINISHED = "list_finished",
  APPROVE = "approve",
  DECLINE = "decline",
  REVOKE = "revoke",
  SCREEN = "screen",
  UPLOAD = "upload",
  ACTIVATE_PROTECTIVE_MEASURE = "activate_protective_measure",
  DISABLE_PROTECTIVE_MEASURE = "disable_protective_measure",
  ACTIVATE = "activate",
  REVOKE_FIX = "revoke_fix",
  DISABLE = "disable",
  SET_STATUS = "set_status",
  LIST_FRIDA_FORM = "list_frida_forms",
  DISMISS = "dismiss",
  GRANT = "grant",
  GENERATE_OPERATIONAL_PROGRAMMING = "generate_operational_programming",
  FORWARD = "forward",
  ARCHIVE = "archive",
  PUBLISH = "publish",
  DASHBOARD_TOTAL = "dashboard_total"
}
