import { TypePermissionEntity } from "@/data/default/types/enumPermission";
import { RouteConfig } from "vue-router";
import RouterDirector from "@/data/default/types/RouterDirector";

const builder = RouterDirector.makeRouterWithPermission({
  name: "redeVizinho",
  permissionEntity: TypePermissionEntity.REDE_VIZINHO,
  componentRoot: () => import("@/screens/default/redeVizinho/Index.vue"),
  componentList: () => import("@/screens/default/redeVizinho/List.vue"),
  componentSave: () => import("@/screens/default/redeVizinho/Save.vue")
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
