import { RouteConfig } from "vue-router";

export default class RouterManager {
  publicRoutes: RouteConfig[] = [];
  adminRoute?: RouteConfig;
  redirectRoute?: RouteConfig;

  addAdminRoute(route): this {
    if (!this.adminRoute) return this;

    if (!this.adminRoute.children) {
      this.adminRoute.children = [];
    }

    this.adminRoute?.children.push(route);
    return this;
  }

  addAdminRouteList(routes: RouteConfig[]): this {
    if (!this.adminRoute) return this;

    if (!this.adminRoute.children) {
      this.adminRoute.children = [];
    }

    this.adminRoute.children = [
      ...(this.adminRoute?.children || []),
      ...routes
    ];
    return this;
  }

  addPublicRouteList(routes: RouteConfig[]): this {
    this.publicRoutes = [...this.publicRoutes, ...routes];
    return this;
  }

  addPublicRoute(route: RouteConfig): this {
    this.publicRoutes.push(route);
    return this;
  }

  setAdminWrapper(adminRoute: RouteConfig): this {
    this.adminRoute = adminRoute;
    return this;
  }

  setRedirect(redirectRoute: RouteConfig): this {
    this.redirectRoute = redirectRoute;
    return this;
  }

  build() {
    return [...this.publicRoutes, this.adminRoute, this.redirectRoute];
  }
}
