import {
  inject,
  provide,
  SetupContext,
  watch,
  ref
} from "@vue/composition-api";
import VueRouter, { Route } from "vue-router";
import { Ref } from "@vue/composition-api";
import { InjectKey } from "vue/types/options";

const routerKey: InjectKey = Symbol();

export const provideRouter = (context: SetupContext) => {
  provide(routerKey, context.root.$router);
};

export default (): { router: VueRouter; route: Ref<Route> } => {
  const router = inject(routerKey) as VueRouter;
  const route: Ref<Route> = ref(router.currentRoute);

  watch(
    () => router,
    newRoute => {
      route.value = newRoute.currentRoute;
    }
  );

  return {
    router,
    route
  };
};
