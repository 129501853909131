import ServiceApi from "@/data/default/api/service";
import {
  Dispositivo,
  DispositivoStatus
} from "@/data/default/types/dispositivo";
import { CustomResponsePagination } from "@/data/default/types/response";
import { DispositivoMapper } from "@/data/default/mappers/dispositivoMapper.ts";

class DispositivoApi extends ServiceApi<Dispositivo> {
  constructor() {
    super({ route: "dispositivo", mapper: new DispositivoMapper() });
  }

  /**
   * Token que é enviado pelo email do usuario
   *
   * @param token String
   */
  public ativacaoEmail(token: string) {
    return this.request.post(`/public/${this.route}/ativacao/${token}`);
  }

  public getAllPendentes(
    page = 1,
    filter = {}
  ): Promise<CustomResponsePagination<Dispositivo>> {
    return this.request
      .get<Dispositivo, CustomResponsePagination<Dispositivo>>(
        `${this.route}/pendente?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      })
      .catch(this.catchRequisitionPagination);
  }

  public getAllRecusados(
    page = 1,
    filter = {}
  ): Promise<CustomResponsePagination<Dispositivo>> {
    return this.request
      .get<Dispositivo, CustomResponsePagination<Dispositivo>>(
        `${this.route}/recusado?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      });
  }

  public getAllAprovados(
    page = 1,
    filter = {}
  ): Promise<CustomResponsePagination<Dispositivo>> {
    return this.request
      .get<Dispositivo, CustomResponsePagination<Dispositivo>>(
        `${this.route}/aprovado?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      });
  }

  public getAllPreAprovados(
    page = 1,
    filter = {}
  ): Promise<CustomResponsePagination<Dispositivo>> {
    return this.request
      .get<Dispositivo, CustomResponsePagination<Dispositivo>>(
        `${this.route}/preaprovado?page=${page}`,
        {
          params: filter
        }
      )
      .then(this._mapLinksPaginationToNumber)
      .then(response => {
        response.params.items = this.fromSourceList(response.params.items);
        return response;
      });
  }

  public recusar(selector: string, payload: IRecusarDispositivo) {
    return this.request.post<Dispositivo>(
      `${this.route}/${selector}/recusar`,
      payload
    );
  }

  public revokeForRegisterFix(selector: string, payload: IRecusarDispositivo) {
    return this.request.post<Dispositivo>(
      `${this.route}/${selector}/revogar`,
      payload
    );
  }

  public setStatus(selector: string, tipo: string) {
    return this.request.post<Dispositivo>(
      `${this.route}/${selector}/notificacao`,
      { tipo }
    );
  }

  public resendEmailConfirmation(idUnico: string) {
    return this.request.put<Dispositivo>(
      `public/${this.route}/${idUnico}/reenviar_email_confirmacao`
    );
  }

  public aproveManually(selector: string) {
    return this.request.put<Dispositivo>(`${this.route}/${selector}/ativar`);
  }
}

export type IRecusarDispositivo =
  | {
      status: DispositivoStatus;
      motivoAnulacao?: string;
    }
  | string;

export default new DispositivoApi();
