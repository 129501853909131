

















import { Component, Mixins, Prop } from "vue-property-decorator";
import { FormMixin } from "./formMixin";

@Component({
  mixins: [FormMixin]
})
export default class FormInput extends Mixins(FormMixin) {
  @Prop({ default: "" }) public description: any;
  @Prop({ default: "text" }) public type!: string;
  @Prop({ default: "md" }) public size!: string;
  @Prop({ default: null }) public maxlength!: number;
}
