/**
 * Dicionário com os erros padrões do sistema de formulário
 * o dicionário trabalha em conjunto com o vuelidate
 * então é muito importante tomar cuidado caso seja trocado
 * o framework que faz a validação.
 */
export const dictionaryInvalidStates: {
  [key: string]: ((...args: any) => string) | string;
} = {
  cpf: (): string => "CPF não está válido",
  url: (): string => "URL não está válida",
  sameAs: (): string => `Campos não coincidem`,
  minLength: ({
    minLength
  }: {
    minLength: { [key: string]: string };
  }): string => `O campo deve ter no mínimo ${minLength.min} caracteres`,
  maxLength: ({
    maxLength
  }: {
    maxLength: { [key: string]: string };
  }): string => `O campo deve ter no máximo ${maxLength.max} caracteres`,
  email: (): string => `Este campo não é um e-mail válido`,
  required: (): string => `Este campo é obrigatório`,
  numeric: (): string => `Este campo deve ser um número`,
  dateIsBefore: (): string =>
    `Este campo deve conter uma data menor que a data final`,
  dateIsBeforeToday: (): string =>
    `O campo deve conter uma data/hora maior que a data/hora atual. `
};
