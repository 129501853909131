import { BvModal } from "bootstrap-vue/src/components/modal";
import { provide, SetupContext, inject } from "@vue/composition-api";
import { BvToast } from "bootstrap-vue";
import { InjectKey } from "vue/types/options";

const bootstrapKey: InjectKey = Symbol();

interface ProvidedBoostrap {
  modal: BvModal;
  toast: BvToast;
}

export const provideBootstrap = (context: SetupContext) => {
  const { $bvModal, $bvToast } = context.root;

  provide(bootstrapKey, {
    modal: $bvModal,
    toast: $bvToast
  } as ProvidedBoostrap);
};

export default (): ProvidedBoostrap | null => {
  const bootstrapInjected = inject(bootstrapKey) as ProvidedBoostrap | null;
  return bootstrapInjected ? bootstrapInjected : null;
};
