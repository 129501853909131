import { Mapper } from "../mapper";
import { Pessoa } from "@/data/default/types/pessoa";
import { MidiaMapper } from "../midiaMapper";
import { Midia } from "@/data/default/types/midia";

export class PessoaMapper extends Mapper<Pessoa> {
  private midiaMapper = new MidiaMapper();

  public fromSource(model: any): Pessoa | null {
    if (!model) {
      return null;
    }
    const midia: Midia = this.midiaMapper.fromSource(
      model.fotoPrincipal
    ) as Midia;

    const pessoa = new Pessoa();
    pessoa.fotoPrincipal = midia;
    pessoa.nome = model.nome;
    pessoa.rg = model.rg;
    pessoa.sexo = model.sexo;
    pessoa.cpf = model.cpf;
    pessoa.enderecoPrincipal = model.enderecoPrincipal;
    pessoa.medidaProtetiva = model.medidaProtetiva;
    pessoa.medidaProtetivaAtiva = model.medidaProtetivaAtiva;
    pessoa.nomeMae = model.nomeMae;
    pessoa.numeroCelular = model.numeroCelular;
    pessoa.dataNascimento = model.dataNascimento;
    pessoa.racaCor = model.racaCor;
    pessoa.profissao = model.profissao;
    pessoa.botaoPanicoAtivado = model.botaoPanicoAtivado;
    pessoa.enderecos = model.enderecos;
    return pessoa;
  }

  public toSource(model: Pessoa | null) {
    if (!model) {
      return null;
    }

    return {
      nome: model.nome,
      rg: model.rg,
      sexo: model.sexo,
      cpf: model.cpf,
      enderecoPrincipal: model.enderecoPrincipal,
      medidaProtetiva: model.medidaProtetiva,
      medidaProtetivaAtiva: model.medidaProtetivaAtiva,
      nomeMae: model.nomeMae,
      numeroCelular: model.numeroCelular,
      dataNascimento: model.dataNascimento,
      racaCor: model.racaCor
    };
  }
}
