import { Component, Vue, Prop } from "vue-property-decorator";
import { InvalidFeedback } from "@/components/Form/InvalidFeedback";

@Component({
  components: {
    InvalidFeedback
  }
})
export class FormMixin extends Vue {
  @Prop() public state: any;
  @Prop({ default: "" }) public label: any;
  @Prop({ required: false }) public value!: any;
  @Prop({ default: "" }) public placeholder!: string;
  @Prop({ default: false, type: Boolean }) public disabled!: boolean;
  @Prop({ type: Object, default: () => ({}) }) public styleLabel!: Record<
    string,
    any
  >;

  public get classValidation() {
    return {
      "is-valid": this.state && this.state.$dirty && !this.state.$error,
      "is-invalid": this.state && this.state.$dirty && this.state.$error
    };
  }
}
