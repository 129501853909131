import { Usuario } from "@/data/default/types/usuario";
import { Mapper } from "./mapper";
import { UsuarioRetaguarda } from "@/data/default/types/usuarioRetaguarda";
import { PessoaEfetivoMapper } from "./pessoaEfetivoMapper";
import { PessoaEfetivo } from "@/data/default/types/pessoaEfetivo";

export default class UsuarioMapper extends Mapper<Usuario> {
  private pessoaRetaguardaMapper = new PessoaEfetivoMapper();

  public fromSource(model: any): Usuario | null {
    if (!model) {
      return null;
    }

    const pessoaEfetivo = model.pessoaEfetivo
      ? (this.pessoaRetaguardaMapper.fromSource(
          model.pessoaEfetivo
        ) as PessoaEfetivo)
      : new PessoaEfetivo();

    const usuario = new UsuarioRetaguarda();
    usuario.username = model.username;
    usuario.email = model.email;
    usuario.pessoaEfetivo = pessoaEfetivo;
    usuario.selector = model.selector;
    usuario.dispositivoAtivo = model.dispositivoAtivo;
    usuario.enabled = model.enabled;
    usuario.centralAtendimento = model.centralAtendimento;
    usuario.areasDespacho = model.areasDespacho;
    usuario.perfil = model.perfil;
    usuario.areaDespachoUsuario = model.areaDespachoUsuario;
    usuario.confirmarEmail = model.email;

    return usuario;
  }

  public toSource(model: UsuarioRetaguarda | null): any {
    if (!model) {
      return {};
    }

    const modelCustom = {
      username: model.username,
      email: model.email,
      selector: model.selector,
      enabled: model.enabled,
      areasDespacho: model.areasDespacho,
      perfil: model.perfil,
      areaDespachoUsuario:
        model.areaDespachoUsuario &&
        typeof model.areaDespachoUsuario !== "string"
          ? model.areaDespachoUsuario.selector
          : ""
    };

    if (model.pessoaEfetivo) {
      Object.assign(modelCustom, {
        pessoaEfetivo: this.pessoaRetaguardaMapper.toSource(model.pessoaEfetivo)
      });
    }

    return modelCustom;
  }
}
