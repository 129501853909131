import useBootstrap from "./useBootstrap";

const useDeleteModal = () => {
  const bootstrap = useBootstrap();

  const open = (confimed: (confirmed: boolean) => void) => {
    bootstrap?.modal
      .msgBoxConfirm("Deseja realmente deletar o item?", {
        title: "Deletar",
        size: "sm",
        centered: true
      })
      .then(confimed);
  };

  return { open };
};

export default useDeleteModal;
