









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class Empty extends Vue {
  @Prop({ default: "Nenhum item encontrado" }) public description!: string;
  @Prop({ default: require("@/assets/default/images/empty.svg") })
  public image!: string;
}
