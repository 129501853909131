






import Vue from "vue";
import { __DEV__ } from "@/utils/index";

export default Vue.extend({
  data: () => ({
    isDevMode: __DEV__
  })
});
