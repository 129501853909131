import maskit from "vue-the-mask/src/maskit";
import tokens from "vue-the-mask/src/tokens";
import constants from "@/config/default/constants";

function mask(value: string, mask: string) {
  return maskit(value, mask, true, tokens);
}

export default mask;

export function maskCpf(value: string) {
  return mask(value, constants.MASKS.CPF);
}

export function maskPhone(value: string) {
  return mask(value, constants.MASKS.PHONE);
}
