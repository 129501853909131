import Vue, { CreateElement, VNode } from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Validation } from "vuelidate";
import { dictionaryInvalidStates } from "./DictionaryInvalidStates";
import { NormalizedScopedSlot } from "vue/types/vnode";

/**
 * Utiliza-se abaixo de um input para mostrar os erros em
 * conjunto com o Vuelidade e suas validações.
 *
 * Exemplo de uso (default):
 * -----------------------------------------------
 * <invalid-feedback :state="$v.form.email"/>
 *
 * Customizando:
 * -----------------------------------------------
 * <invalid-feedback :state="$v.form.email">
 *  <template v-slot:required>
 *    Lorem ipsum
 *  </template>
 * </invalid-feedback>
 */
@Component
export class InvalidFeedback extends Vue {
  @Prop({ required: true }) private state!: Validation | any;
  private classContainer = "invalid-feedback d-block";

  /**
   * Renderiza
   *
   * @param h CreateElement do virtual dom
   * @param content Conteudo que será renderizado
   */
  public renderInvalidFeedback(
    h: CreateElement,
    content: string | VNode[] | undefined
  ): VNode {
    return h("div", { class: this.classContainer }, content);
  }

  public render(
    h: CreateElement
  ): NormalizedScopedSlot | VNode | VNode[] | undefined {
    if (!this.state || !this.state.$error) {
      return;
    }

    for (const propertie of Object.getOwnPropertyNames(
      dictionaryInvalidStates
    )) {
      /**
       * Verifica se existe dentro do estado de validação
       * se existir verifica se ele já não está válido
       * para evitar busca desnecessária
       */
      if (!(propertie in this.state)) {
        continue;
      } else if (this.state[propertie] === true) {
        continue;
      }

      if (propertie in this.$scopedSlots) {
        const scopedFinded = this.$scopedSlots[propertie];
        /**
         * Utiliza-se dentro de slot com scopo, depois de encontrar um
         * scopedFinded
         * <template v-slot:nomeDoSlot>
         *  lorem ipsum
         * </template>
         */
        return this.renderInvalidFeedback(h, scopedFinded!({}));
      }

      const findedErrorInDictionary = dictionaryInvalidStates[propertie];
      if (typeof findedErrorInDictionary === "function") {
        return this.renderInvalidFeedback(
          h,
          findedErrorInDictionary(this.state.$params)
        );
      }

      if (typeof findedErrorInDictionary === "string") {
        return this.renderInvalidFeedback(h, findedErrorInDictionary);
      }
    }
  }
}
