import dispositivoApi from "@/data/default/api/dispositivo";
import { ActionContext } from "vuex";
import * as types from "./types";
import { AxiosResponse } from "axios";
import constants from "@/config/default/constants.ts";

export interface AtivacaoEmailState {
  loading: boolean;
  successMessage: string;
  defaultMessage: string;
  success: boolean | null;
}

const state: AtivacaoEmailState = {
  loading: false,
  successMessage: `Tudo ocorreu como esperado e o seu aplicativo ${constants.getApplicationName()} está pronto para uso!`,
  defaultMessage: `É necessário clicar no botão abaixo para que sua conta seja ativada com sucesso!`,
  success: null
};

const mutations = {
  [types.mutations.SET_LOADING]: (
    state: AtivacaoEmailState,
    active: boolean
  ): void => {
    state.loading = active;
  },
  [types.mutations.SET_SUCCESS]: (
    state: AtivacaoEmailState,
    success: boolean
  ): void => {
    state.success = success;
  }
};

const actions = {
  [types.actions.ACTIVE](
    {}: ActionContext<AtivacaoEmailState, {}>,
    token: string
  ): Promise<AxiosResponse<any>> {
    return dispositivoApi.ativacaoEmail(token);
  }
};

export default {
  namespaced: true,
  mutations,
  actions,
  state
};
