



















import { Component, Mixins, Prop } from "vue-property-decorator";
import { FormMixin } from "./formMixin";

@Component({
  mixins: [FormMixin]
})
export default class FormCheckbox extends Mixins(FormMixin) {
  @Prop({ default: "" }) public description!: string;
  @Prop({ default: "" }) public fieldText!: string;
}
