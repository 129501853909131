import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faPlus,
  faEdit,
  faTrash,
  faMobileAlt,
  faCog,
  faFilter,
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faFlag,
  faCheck,
  faBan,
  faTimes,
  faEye,
  faQuestionCircle,
  faSort,
  faCamera,
  faFileImport,
  faExternalLinkAlt,
  faFile,
  faStream,
  faUser,
  faUserShield,
  faExclamationTriangle,
  faCheckCircle,
  faCalendarDay,
  faShare,
  faComments,
  faChevronDown,
  faPrint,
  faArchive,
  faAngleRight,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faCopy,
  faQuestion,
  faBullseye,
  faCompass,
  faDrawPolygon,
  faEnvelope,
  faBullhorn,
  faPlaneDeparture,
  faPlaneArrival,
  faClipboard,
  faClipboardList,
  faCity,
  faUsers,
  faFileAlt,
  faPenAlt,
  faMapMarkerAlt,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

library.add(faSyncAlt);
library.add(faPlus);
library.add(faEdit);
library.add(faTrash);
library.add(faMobileAlt);
library.add(faCog);
library.add(faFilter);
library.add(faArrowLeft);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faFlag);
library.add(faCheck);
library.add(faBan);
library.add(faTimes);
library.add(faEye);
library.add(faQuestionCircle);
library.add(faSort);
library.add(faCamera);
library.add(faFileImport);
library.add(faExternalLinkAlt);
library.add(faFile);
library.add(faStream);
library.add(faUser);
library.add(faUserShield);
library.add(faExclamationTriangle);
library.add(faCheckCircle);
library.add(faCalendarDay);
library.add(faShare);
library.add(faComments);
library.add(faPrint);
library.add(faArchive);
library.add(faAngleRight);
library.add(faAngleDoubleRight);
library.add(faAngleLeft);
library.add(faAngleDoubleLeft);
library.add(faCopy);
library.add(faQuestion);
library.add(faBullseye);
library.add(faCompass);
library.add(faDrawPolygon);
library.add(faEnvelope);
library.add(faBullhorn);
library.add(faPlaneDeparture);
library.add(faPlaneArrival);
library.add(faClipboard);
library.add(faClipboardList);
library.add(faCity);
library.add(faUsers);
library.add(faFileAlt);
library.add(faPenAlt);
library.add(faMapMarkerAlt);
library.add(faEyeSlash);
