

















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ActionbarSave extends Vue {
  @Prop({ required: false, default: false }) public readonly loading!: boolean;
  @Prop({ default: true }) public readonly showBtnList!: boolean;

  public handlerGoToList() {
    this.$emit("toList");
  }
}
