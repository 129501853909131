



































































import { ref, watch, Ref } from "@vue/composition-api";
import { InvalidFeedback } from "./InvalidFeedback";
import { OptionAutocomplete } from "./FormAutocompleteCustom.vue";

export default {
  components: {
    InvalidFeedback
  },
  props: {
    label: {
      required: false,
      type: String,
      default: ""
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    menuOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    state: {
      required: false,
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    size: {
      type: Number,
      required: false,
      default: 5
    },
    value: {
      type: Array,
      default: () => []
    },
    serializer: {
      type: Function,
      default: item => item.descricao
    },
    serializerKey: {
      type: Function,
      default: item => item.selector
    }
  },
  setup(props: any, { emit }) {
    const valueFirstList = ref<any[]>([]);
    const optionsFirstList = ref<OptionAutocomplete[]>([]);
    const firstAutocompleteRef = ref<any>(null);

    const secondAutocompleteRef = ref<any>(null);
    const valueSecondList = ref<any[]>([]);
    const optionsSecondList = ref<OptionAutocomplete[]>([]);

    const transferBetweenArray = (
      from: Ref<any[]>,
      to: Ref<any[]>,
      keys
    ): void => {
      const newFrom = from.value.filter(
        item => !keys.includes(props.serializerKey(item))
      );
      const newTo = [
        ...to.value,
        ...from.value.filter(item => keys.includes(props.serializerKey(item)))
      ];

      from.value = newFrom;
      to.value = newTo;
    };

    const updateTransferredItems = (newValue: any[]) => {
      if (!newValue) return;

      const toRemoveIds: number[] = newValue.map(option =>
        props.serializerKey(option)
      );
      transferBetweenArray(optionsFirstList, optionsSecondList, toRemoveIds);
    };

    watch(
      () => props.menuOptions,
      (newValue: any[]) => {
        optionsFirstList.value = newValue;
        updateTransferredItems(props.value);
      }
    );

    watch(
      () => props.value,
      (newValue: any[]) => {
        updateTransferredItems(newValue);
      }
    );

    function emitNewValues(selectedOptions: any) {
      emit("input", selectedOptions);
    }

    const addSelecteds = () => {
      const toRemoveIds: number[] = valueFirstList.value.map(option =>
        props.serializerKey(option)
      );

      transferBetweenArray(optionsFirstList, optionsSecondList, toRemoveIds);
      emitNewValues(optionsSecondList.value);

      if (firstAutocompleteRef.value) firstAutocompleteRef.value.resetSelects();
    };

    const addAll = () => {
      const newFirstOptions = [];
      const newSecondOptions = [
        ...optionsFirstList.value,
        ...optionsSecondList.value
      ];

      optionsFirstList.value = newFirstOptions;
      optionsSecondList.value = newSecondOptions;

      emitNewValues(optionsSecondList.value);
    };

    const removeSelecteds = () => {
      const toRemoveIds: number[] = valueSecondList.value.map(option =>
        props.serializerKey(option)
      );

      transferBetweenArray(optionsSecondList, optionsFirstList, toRemoveIds);
      emitNewValues(optionsSecondList.value);

      if (secondAutocompleteRef.value)
        secondAutocompleteRef.value.resetSelects();
    };

    const removeAll = () => {
      const newFirstOptions = [
        ...optionsFirstList.value,
        ...optionsSecondList.value
      ];
      const newSecondOptions = [];

      optionsFirstList.value = newFirstOptions;
      optionsSecondList.value = newSecondOptions;

      emitNewValues(optionsSecondList.value);
    };

    return {
      // Primeira listagem
      valueFirstList,
      optionsFirstList,
      firstAutocompleteRef,
      // Segunda listagem
      valueSecondList,
      optionsSecondList,
      secondAutocompleteRef,
      // Funções
      addSelecteds,
      addAll,
      removeSelecteds,
      removeAll
    };
  }
};
