import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

const sentryUrl = process.env.VUE_APP_SENTRY_IO;

if (sentryUrl) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: sentryUrl,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
    ]
  });
}
