
































import Vue from "vue";
import { FormMixin } from "./formMixin";
import VueBootstrapTypeahead from "vue-typeahead-bootstrap/src/components/VueBootstrapTypeahead.vue";
import { watch, ref } from "@vue/composition-api";
import constants from "@/config/default/constants.ts";

export default Vue.extend({
  components: {
    VueBootstrapTypeahead
  },
  mixins: [FormMixin],
  props: {
    data: {
      required: true,
      type: Array,
      default: () => []
    },
    serializer: {
      required: false,
      default: (value: any) => value,
      type: Function
    },
    helper: {
      required: false,
      type: String,
      default: ""
    },
    helperFilterAutocomplete: {
      required: false,
      type: Boolean,
      default: false
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup(props: any, { emit }) {
    const bootstrapTypeahead = ref<any>(null);
    const localValue = ref<string>(props.value ?? "");
    watch(
      () => props.value,
      value => {
        if (!localValue.value && bootstrapTypeahead.value) {
          bootstrapTypeahead.value.handleInput(value);
          localValue.value = value;
        }
      }
    );

    const localHelper = ref(props.helper);

    if (props.helperFilterAutocomplete) {
      localHelper.value = constants.TEXTS.HELPER_FILTER_AUTOCOMPLETE;
    }

    const handleHit = (item: any) => {
      emit("hit", item);
    };

    const handleInput = (event: string) => {
      emit("input", event);
    };

    return {
      handleHit,
      handleInput,
      localValue,
      bootstrapTypeahead,
      localHelper
    };
  }
});
