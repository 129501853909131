import { Mapper } from "./mapper";
import { PessoaEfetivo } from "@/data/default/types/pessoaEfetivo";

export class PessoaEfetivoMapper extends Mapper<PessoaEfetivo> {
  public fromSource(model: any): PessoaEfetivo | null {
    if (!model) return null;

    const pessoa = new PessoaEfetivo();
    pessoa.selector = model.selector;
    pessoa.nome = model.nome;
    pessoa.sexo = model.sexo;
    pessoa.cpf = model.cpf;
    pessoa.dataNascimento = model.dataNascimento;
    pessoa.matricula = model.matricula;
    pessoa.postoGraduacao = model.postoGraduacao;
    pessoa.digito = model.digito;
    pessoa.telefone = model.telefone;
    pessoa.unidade = model.unidade;
    pessoa.efetivo = model.efetivo ? model.efetivo.selector : null;
    return pessoa;
  }

  public toSource(model: PessoaEfetivo | null) {
    if (!model) return null;

    return {
      selector: model.selector,
      nome: model.nome,
      cpf: model.cpf,
      matricula: model.matricula,
      postoGraduacao: model.postoGraduacao,
      digito: model.digito,
      telefone: model.telefone,
      unidade:
        model.unidade && typeof model.unidade !== "string"
          ? model.unidade.selector
          : ""
    };
  }
}
