export interface Mapper<T> {
  fromSource(model: any): T | null;
  toSource(model: T): any;
}

export abstract class Mapper<T> implements Mapper<T> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fromSource(model: any): T | null {
    throw new Error("Method not implemented.");
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public toSource(model: any) {
    throw new Error("Method not implemented.");
  }

  public fromSourceList(models: any[]) {
    models = models.map<any>(item => {
      return this.fromSource(item);
    });

    return models;
  }

  public toSourceList(models: any[]) {
    models = models.map<any>(item => {
      return this.toSource(item);
    });

    return models;
  }
}
