import { inject, provide, SetupContext } from "@vue/composition-api";
import { Store } from "vuex";
import { InjectKey } from "vue/types/options";

const storeKey: InjectKey = Symbol();

export const provideStore = (context: SetupContext) => {
  provide(storeKey, context.root.$store);
};

export default <T>(): Store<T> => {
  return inject(storeKey) as Store<T>;
};
