import jsonWebToken from "jsonwebtoken";
import { JWTDecode } from "@/data/default/types/autenticacao";
import {
  TypePermissionEntity,
  TypePermissionAction
} from "@/data/default/types/enumPermission";
import { BrowserStorage } from "./browserStorage";

enum TokenTypes {
  TOKEN = "token",
  REFRESH_TOKEN = "refresh_token"
}

export default {
  get token(): string {
    return BrowserStorage.get(TokenTypes.TOKEN) as string;
  },
  set token(token: string) {
    BrowserStorage.set(TokenTypes.TOKEN, token);
  },
  get refresh(): string {
    return BrowserStorage.get(TokenTypes.REFRESH_TOKEN) as string;
  },
  set refresh(refreshToken: string) {
    BrowserStorage.set(TokenTypes.REFRESH_TOKEN, refreshToken);
  },
  authClearAll(): void {
    BrowserStorage.remove(TokenTypes.TOKEN);
    BrowserStorage.remove(TokenTypes.REFRESH_TOKEN);
  },
  decode<T>(token: string): T {
    return jsonWebToken.decode(token) as T;
  },
  hasPermission(
    entity: TypePermissionEntity | string,
    action?: TypePermissionAction | string
  ): boolean {
    const decoded: JWTDecode = this.decode<JWTDecode>(this.token);
    if (!decoded) return false;

    const decodedPermissions = decoded.permissoes;

    if (!decodedPermissions) return false;

    const permissionFinded = decodedPermissions[entity];

    const isEmptyArray = permissionFinded =>
      Array.isArray(permissionFinded) && permissionFinded.length === 0;

    if (!permissionFinded || isEmptyArray(permissionFinded)) return false;
    if (!action) return !!permissionFinded;

    return permissionFinded && permissionFinded.includes(action);
  }
};
