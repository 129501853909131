export class ResponseActionType {
  public method: string;
  public title: string;

  constructor(method: string, title: string) {
    this.method = method;
    this.title = title;
  }
}

export const getMainActions = (): ResponseActionType[] => {
  return [
    new ResponseActionType("post", "Salvar"),
    new ResponseActionType("put", "Editar"),
    new ResponseActionType("delete", "Excluir")
  ];
};
