export interface AuthResponse {
  token?: string;
  refresh_token?: string;
}

export default interface CustomResponse<T> extends AuthResponse {
  params: T;
  message: string;
  status: StatusResponse;
}

export enum StatusResponse {
  SUCCESS = "success",
  FAIL = "fail",
  WAITING_USER_ACTIVATE = "waiting_user_activate"
}

export interface Pagination<T> {
  count: number;
  items: T[];
  maxPerPage: number;
  counters: any;
  total: number;
  links: {
    self: number;
    first: number;
    last: number;
  };
}

export interface CustomResponsePagination<T> {
  params: Pagination<T>;
  message: string;
  status: "success" | "fail";
}
