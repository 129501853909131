import { Base } from "@/data/default/types/base";
import { UsuarioMobile } from "@/data/default/types/usuarioMobile";
import { Pessoa } from "@/data/default/types/pessoa";

export enum DispositivoStatus {
  SOLICITACAO_APROVADA = "solicitacao_aprovada",
  SOLICITACAO_REPROVADA = "solicitacao_reprovada",
  AUTORIZACAO_APROVADA = "autorizacao_aprovada",
  AUTORIZACAO_REPROVADA = "autorizacao_reprovada",
  SOLICITACAO_PRE_APROVADA = "solicitacao_pre_aprovada",
  AUTORIZACAO_REPROVADA_AJUSTE = "autorizacao_reprovada_ajuste"
}

export class Dispositivo extends Base {
  get status() {
    return this.privateStatus;
  }

  set status(value: number) {
    this.canAprovar = value === 0 || value === 4 || value === 2 || value === 6;
    this.canRevogar = value === 1 || value === 3 || value === 5;
    this.canRecusar = value === 0;

    this.isPendente = value === 0;
    this.isRevogado = value === 4 || value === 2 || value === 6;
    this.isAprovado = value === 3 || value === 1;
    this.isPreAprovado = value === 5;
    this.privateStatus = value;
  }

  public idUnico?: string;
  public usuario?: UsuarioMobile;
  public usuarioAntigo?: Pessoa | null;
  public modelo?: string;
  public dataCriacao?: string;
  public tokenNotificacao?: string;
  public motivoAnulacao?: string;
  public imei?: string;
  public idfa?: string;
  public canAprovar = false;
  public canRevogar = false;
  public canRecusar = false;
  public isPendente = false;
  public isRevogado = false;
  public isAprovado = false;
  public isPreAprovado = false;
  private privateStatus = 0;

  constructor() {
    super();
    this.usuario = new UsuarioMobile();
  }

  get statusDescription() {
    switch (this.privateStatus) {
      case 0:
        return "Pendente";
      case 1:
        return "Aprovado";
      case 2:
        return "Recusado";
      case 3:
        return "Autorização aprovada";
      case 4:
        return "Revogado";
      case 5:
        return "Pré-aprovado";
      case 6:
        return "Revogado para ajuste";
      default:
        return "Status não encontrado";
    }
  }

  public getStatusAprovar(): DispositivoStatus | null {
    if (this.isPendente || this.isRevogado) {
      return DispositivoStatus.SOLICITACAO_PRE_APROVADA;
    } else {
      return null;
    }
  }

  public getStatusReprovar(): DispositivoStatus | null {
    if (this.isPendente) {
      return DispositivoStatus.SOLICITACAO_REPROVADA;
    } else if (this.isAprovado) {
      return DispositivoStatus.AUTORIZACAO_REPROVADA;
    } else {
      return null;
    }
  }

  public getStatusRevogar(): DispositivoStatus | null {
    if (this.isAprovado || this.isPreAprovado) {
      return DispositivoStatus.AUTORIZACAO_REPROVADA;
    } else {
      return null;
    }
  }

  public getStatusRevogarParaAjuste(): DispositivoStatus | null {
    if (this.isAprovado || this.isPreAprovado || this.isPendente) {
      return DispositivoStatus.AUTORIZACAO_REPROVADA_AJUSTE;
    } else {
      return null;
    }
  }
}
