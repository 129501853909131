<template>
  <b-table
    striped
    :items="source"
    :fields="fields"
    :busy="loading"
    :tbody-tr-class="rowClass"
    class="m-0"
    show-empty
    responsive="xl"
  >
    <template slot="empty">
      <empty />
    </template>

    <template slot="table-busy">
      <div class="text-center mt-3">
        <b-spinner variant="primary" label="Spinning" />
        <p class="mt-3">
          Carregando informações
        </p>
      </div>
    </template>

    <template
      v-for="slotObj of slotsComputeds"
      v-slot:[`cell(${slotObj.key})`]="slotProps"
    >
      <slot :name="slotObj.key" :item="slotProps.item" />
    </template>

    <template #acao="{ item }">
      <slot name="acao" :item="item" />
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    source: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowClass: {
      type: Function,
      default: () => ({})
    }
  },
  computed: {
    slotsComputeds() {
      const slots = this.$scopedSlots;
      return Object.keys(slots).map(key => {
        return {
          key,
          slot: slots[key]
        };
      });
    }
  }
};
</script>
