import Vue from "vue";
import VueRouter from "vue-router";
import defaultRoutes from "@/screens/default/router.ts";

Vue.use(VueRouter);

const createRouter = (routes: any[]): VueRouter =>
  new VueRouter({
    routes
  });

export default createRouter(defaultRoutes.build());
