













import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class InlineCounters extends Vue {
  @Prop({ type: Array, required: false }) public items;
}
