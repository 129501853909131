



















import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class Filterbar extends Vue {
  @Prop({ default: true }) public readonly show: boolean | undefined;
}
