import { CentralAtendimento } from "@/data/default/types/centralAtendimento";
import { Usuario } from "./usuario";
import { PessoaEfetivo } from "./pessoaEfetivo";
import { AreaDespacho } from "./areaDespacho";

export class UsuarioRetaguarda extends Usuario {
  public pessoaEfetivo?: PessoaEfetivo;
  public confirmarEmail?: string;

  constructor() {
    super();
    this.pessoaEfetivo = new PessoaEfetivo();
    this.areaDespachoUsuario = new AreaDespacho();
    this.areasDespacho = [];
    this.centralAtendimento = new CentralAtendimento();
    this.confirmarSenha = "";
    this.dispositivoAtivo = undefined;
    this.email = "";
    this.confirmarEmail = "";
    this.enabled = true;
    this.perfil = "";
    this.username = "";
  }
}
