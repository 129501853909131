import { Usuario } from "./usuario";
import { Pessoa } from "./pessoa";
import { ContadoresDispositivo } from "./contadoresDispositivo";

export class UsuarioMobile extends Usuario {
  public pessoa: Pessoa;
  public contadoresDispositivo?: ContadoresDispositivo;
  public totalDispositivosLiberados?: number;
  public totalDispositivosLiberadosAnteriormente?: number;
  public totalDispositivos?: number;

  constructor() {
    super();
    this.pessoa = new Pessoa();
  }
}
