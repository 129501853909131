export default {
  APP_PREFIX_NAME: "EGSYS",
  APP_NAME: "Cidadão",
  DEFAULT_IMAGE_EMPTY_PHOTO: require("@/assets/default/images/empty_image.jpg"),
  PERFIL: {
    ESTADUAL: "ESTADUAL",
    UNIDADE: "UNIDADE"
  },
  TIPO_PERGUNTAS_ENQUETE: {
    OBJETIVA: "OBJETIVA",
    DISSERTATIVA: "DISSERTATIVA",
    MULTIPLA: "MULTIPLA",
    ORDENACAO: "ORDENACAO"
  },
  TEXTS: {
    HELPER_FILTER_AUTOCOMPLETE:
      "Digite o texto de busca e selecione uma opção antes de filtrar",
    CITIZEN_OFFLINE: `Cidadão offline há mais de 
      ${process.env.VUE_APP_CITIZEN_OFFLINE_TIME || "2 minutos"}`
  },
  MASKS: {
    CPF: "###.###.###-##",
    PHONE: "(##) # ####-####"
  },
  getApplicationName() {
    return `${this.APP_PREFIX_NAME} ${this.APP_NAME}`;
  }
};
