/* tslint:disable:no-console */

import { register } from "register-service-worker";
import { BModal } from "bootstrap-vue";

const TIME_INTERVAL = 10000;

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered(sw: ServiceWorkerRegistration) {
      console.log("Service worker has been registered.");
      setInterval(() => {
        sw.update();
      }, TIME_INTERVAL);
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(sw: ServiceWorkerRegistration) {
      let refreshing = false;
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (refreshing) {
          return;
        }
        refreshing = true;
        window.location.reload();
      });

      const handleConfirm = (confirm: boolean) => {
        confirm && sw.waiting?.postMessage("skipWaiting");
      };

      new BModal().$bvModal
        .msgBoxConfirm(
          "Uma nova versão foi disponibilizada. Atualize sua página para ter acesso!",
          {
            size: "sm",
            okTitle: "Atualizar página",
            cancelTitle: "Depois"
          }
        )
        .then(handleConfirm);

      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
