import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import VueTheMask from "vue-the-mask";
import BootstrapVue from "bootstrap-vue";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";
import VueCompositionApi from "@vue/composition-api";

import "./registerServiceWorker";

// Plugins
import "@/shared/plugins/icons";
import "@/shared/plugins/sentry";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "@/assets/default/styles/theme.scss";
import "@/assets/default/styles/index.scss";
import "cropperjs/dist/cropper.css";
import "@/components/index";
import "@/shared/directives/index";
import permission from "@/shared/mixins/permission";

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueCompositionApi);

Vue.config.productionTip = false;

new Vue({
  mixins: [permission],
  router,
  store,
  render: h => h(App)
}).$mount("#app");
