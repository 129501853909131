











































import { computed, ref, watch } from "@vue/composition-api";
import { InvalidFeedback } from "./InvalidFeedback";

type Serializer = (source: any) => string;

export interface OptionAutocomplete {
  id: number;
  text: string;
  source: any;
}

const createOption = (
  id: number,
  source: any,
  serializer: Serializer
): OptionAutocomplete => ({
  id,
  text: serializer(source),
  source
});

export default {
  components: {
    InvalidFeedback
  },
  props: {
    label: {
      required: false,
      type: String,
      default: ""
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    state: {
      required: false,
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 5,
      required: false
    },
    serializer: {
      type: Function,
      default: (a: any) => a
    },
    serializerKey: {
      type: Function,
      default: (a: any) => a.selector
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      validator: prop =>
        typeof prop === "object" ||
        Array.isArray(prop) ||
        typeof prop === "string" ||
        prop === null ||
        prop === undefined,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(params: any, { emit }: any) {
    // local-data
    const localData = computed<OptionAutocomplete[]>(() =>
      params.options.map((option: any, index: number) => {
        return createOption(index, option, params.serializer as Serializer);
      })
    );

    // local-data-filtered
    const localDataFiltered = ref(localData.value);

    // query
    const filterLocalData = (query: string): Array<any> => {
      return localData.value.filter(item =>
        item.text.toLowerCase().includes(query.toLowerCase())
      );
    };

    const query = ref("");
    watch([query, localData], ([newValue]) => {
      if (newValue !== undefined)
        localDataFiltered.value = filterLocalData(newValue as string);
    });

    const handleSelect = (event: any) => {
      const findItemByValue = (value: string) =>
        localData.value.find(item => item.id === parseInt(value));

      if (params.multiple) {
        const newValues: OptionAutocomplete[] = [];
        for (const option of event.target.selectedOptions) {
          const optionValue: string = option.value as string;
          const optionFinded = findItemByValue(optionValue);

          if (optionFinded) {
            newValues.push(optionFinded);
          }
        }

        emit(
          "input",
          newValues.map(item => (item ? item.source : null))
        );
        emit("selected", {
          options: event.target.options
        });
      } else {
        const finded = findItemByValue(event.target.value);
        emit("input", finded ? finded.source : null);
      }
    };

    const isSelected = (item: any) => {
      if (params.multiple) {
        return params.value.find(
          value =>
            params.serializerKey(value) == params.serializerKey(item.source)
        );
      }
      return params.value
        ? params.serializerKey(item.source) ==
            params.serializerKey(params.value)
        : false;
    };

    const selectRef = ref<any>(null);
    const resetSelects = () => {
      if (!selectRef.value) return;
      selectRef.value
        .querySelectorAll("option")
        .forEach(item => (item.selected = false));
    };

    return {
      localDataFiltered,
      query,
      handleSelect,
      isSelected,
      selectRef,
      resetSelects
    };
  }
};
