import { Mapper } from "../mapper";
import { UsuarioMobile } from "@/data/default/types/usuarioMobile";
import { Pessoa } from "@/data/default/types/pessoa";

export class UsuarioMobileMapper extends Mapper<UsuarioMobile> {
  constructor(private pessoaMapper: Mapper<any>) {
    super();
  }

  public fromSource(model: any): UsuarioMobile {
    const usuario = new UsuarioMobile();
    usuario.username = model.username;
    usuario.email = model.email;

    if (model.pessoa) {
      usuario.pessoa = this.pessoaMapper.fromSource(model.pessoa) as Pessoa;
    } else {
      usuario.pessoa = new Pessoa();
    }

    usuario.selector = model.selector;
    usuario.dispositivoAtivo = model.dispositivoAtivo;
    usuario.enabled = model.enabled;
    usuario.contadoresDispositivo = model.contadoresDispositivo;
    usuario.areaDespachoUsuario = model.areaDespachoUsuario;
    usuario.totalDispositivosLiberados = model.totalDispositivosLiberados;
    usuario.totalDispositivosLiberadosAnteriormente =
      model.totalDispositivosLiberadosAnteriormente;
    usuario.totalDispositivos = model.totalDispositivos;
    return usuario;
  }

  public toSource(model: UsuarioMobile): any {
    return model;
  }
}
