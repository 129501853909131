import { Usuario, UsuarioLogin } from "@/data/default/types/usuario";
import CustomResponse, { AuthResponse } from "@/data/default/types/response";
import ServiceApi from "@/data/default/api/service";
import Auth from "@/utils/auth";
import UsuarioRetaguardaMapper from "@/data/default/mappers/usuarioRetaguardaMapper";
import { ResponseAutenticacao } from "@/data/default/types/autenticacao";
import { ContadoresDispositivo } from "@/data/default/types/contadoresDispositivo";
import { TrocaSenhaProvisoria } from "@/data/default/types/trocaSenhaProvisoria";

class UsuarioApi extends ServiceApi<Usuario> {
  constructor() {
    super({ route: "usuario", mapper: new UsuarioRetaguardaMapper() });
  }

  public autenticacao(
    params: UsuarioLogin
  ): Promise<CustomResponse<ResponseAutenticacao>> {
    return this.request.post("login", params).then((response: any) => {
      this.setAuth(response.token, response.refresh_token);
      return response;
    });
  }

  public refresh(refreshToken: string): Promise<AuthResponse> {
    return (
      this.request
        // eslint-disable-next-line @typescript-eslint/camelcase
        .post("token/refresh", { refresh_token: refreshToken })
        .then((response: any) => {
          this.setAuth(response.token, response.refresh_token);
          return response;
        })
    );
  }

  /**
   * Verifica se cpf ou e-mail já existe
   * @param unique CPF ou E-mail
   */
  public exists(unique: string): Promise<boolean> {
    return this.request
      .get(`${this.route}/exists/${unique}`)
      .then(() => true)
      .catch(() => false);
  }

  private setAuth(token: string, refreshToken: string) {
    Auth.token = token;
    Auth.refresh = refreshToken;
  }

  //dispositivos liberados
  public getReleasedDevicesCountByMobileSelector(
    selector: string
  ): Promise<CustomResponse<Array<ContadoresDispositivo>>> {
    return this.request.get(`${this.route}/${selector}/dispositivo`);
  }

  public changeTemporaryPassword(
    changePasswordForm: TrocaSenhaProvisoria
  ): Promise<CustomResponse<TrocaSenhaProvisoria>> {
    return this.request.post(`${this.route}/primeiro_acesso`, {
      matricula: changePasswordForm.matricula,
      currentPassword: changePasswordForm.senhaProvisoria,
      plainPassword: {
        plainPassword: changePasswordForm.senha,
        confirmPlainPassword: changePasswordForm.confirmarSenha
      }
    });
  }

  public resetPassword(userEmail: string): any {
    return this.request.post(`${this.route}/resetar_senha`, {
      usuario: userEmail
    });
  }
}

export default new UsuarioApi();
