


























import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "./formMixin";
import DatePicker from "vue2-datepicker";
import moment from "moment";

@Component({
  components: {
    DatePicker
  }
})
export default class FormInput extends Mixins(FormMixin) {
  @Prop({ default: "" }) public description: any;
  @Prop({ default: "", type: String }) public type: any;
  @Prop({ default: false }) public range!: boolean;
  @Prop({ default: "DD/MM/YYYY" }) public format!: string;
  @Prop({ default: null }) public formatBind!: string;
  @Prop({ default: null, type: Function }) public disabledDays!: any;
  @Prop({ default: false, type: Boolean }) public onlyFromToday!: boolean;
  @Prop({ default: null, type: String }) public minDate!: string;

  public disabledDaysFunction: Function | null = null;

  @Watch("minDate")
  handleWatchMinDate(newValue: any) {
    this.disabledDaysFunction = newValue ? this.disabledDayByMinDate : null;
  }

  public mounted() {
    if (this.disabledDays) {
      this.disabledDaysFunction = this.disabledDays;
    }

    if (this.onlyFromToday)
      this.disabledDaysFunction = this.disabledDaysAfterToday;
  }

  public disabledDayByMinDate(day: any) {
    return moment(this.minDate).isAfter(day, "day");
  }

  public disabledDaysAfterToday(day: any) {
    return moment().isAfter(day, "day");
  }

  public onChange(event: Date | Date[]) {
    const eventArray = Array.isArray(event) ? event : [event];
    const finalDate: string | string[] = eventArray
      .map(date => moment(date))
      .filter(date => date.isValid())
      .map(date => date.format(this.formatBind || "YYYY-MM-DD"));

    this.$emit("change", finalDate.length === 1 ? finalDate[0] : finalDate);
  }

  public valueType = {
    /**
     * Value binding para data do componente
     */
    value2date: (value: any) => {
      if ((this.range as any) === "") {
        return value;
      }

      if (!value) {
        return "";
      }

      return this.formatBind
        ? moment(value, this.formatBind).valueOf()
        : moment(value).valueOf();
    },
    /**
     * Valor do componente para o value binding
     */
    date2value: (date: Date) => {
      if ((this.range as any) === "") {
        return date;
      }

      const dateValue = moment(date);
      return dateValue.isValid()
        ? this.formatBind
          ? dateValue.format(this.formatBind)
          : dateValue.format()
        : "";
    }
  };
}
