import { Mapper } from "@/data/default/mappers/mapper";
import { Dispositivo } from "@/data/ro/types/dispositivo";
import { UsuarioMobileMapper } from "@/data/default/mappers/usuarioMobile/usuarioMobileMapper";
import { PessoaMapper } from "@/data/default/mappers/pessoa/pessoaMapper";

export class DispositivoMapper extends Mapper<Dispositivo> {
  private usuarioMobileMapper = new UsuarioMobileMapper(new PessoaMapper());
  private pessoaMapper = new PessoaMapper();

  public fromSource(model: any): Dispositivo | null {
    if (!model) {
      return null;
    }

    const dispositivo = new Dispositivo();
    dispositivo.modelo = model.modelo;
    dispositivo.idUnico = model.idUnico;
    dispositivo.status = model.status;
    dispositivo.dataCriacao = model.dataCriacao;
    dispositivo.selector = model.selector;
    dispositivo.tokenNotificacao = model.tokenNotificacao;
    dispositivo.imei = model.imei;
    dispositivo.idfa = model.idfa;
    dispositivo.usuario = this.usuarioMobileMapper.fromSource(model.usuario);
    dispositivo.usuarioAntigo = this.pessoaMapper.fromSource(
      model.usuarioAntigo
    );
    dispositivo.motivoAnulacao = model.motivoAnulacao;
    return dispositivo;
  }

  public toSource(model: Dispositivo) {
    return {
      modelo: model.modelo,
      idUnico: model.idUnico,
      status: model.status,
      dataCriacao: model.dataCriacao,
      tokenNotificacao: model.tokenNotificacao,
      usuario: model.usuario
        ? this.usuarioMobileMapper.toSource(model.usuario)
        : null,
      selector: model.selector
    };
  }
}
