import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import Vue from "vue";
import auth from "@/utils/auth";
import UsuarioApi from "./usuario";
import Auth from "@/utils/auth";
import { getMainActions } from "@/data/default/types/responseActionType";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 70000
});

/**
 * Colocar dentro do header authorization
 * => Bearer ${token}
 * @param options AxiosRequestConfig
 */
function putAuthorizarionOnHeader(options: AxiosRequestConfig): void {
  if (!options) {
    return;
  }

  options.headers.authorization = `Bearer ${auth.token}`;
}

function notifySuccess(title?: string, text?: string): void {
  Vue.notify({
    title: title,
    type: "success",
    text
  });
}

function checkActionToNotify(response: AxiosResponse) {
  const actions = getMainActions();

  const selectedAction = actions.find(
    action => action.method == response.config.method?.toLowerCase()
  );

  if (response.status === 200 && selectedAction && response.data.message) {
    notifySuccess(selectedAction.title, response.data.message);
  }
}

/**
 * Pega a data de dentro do response do axios
 * para facilitar o uso de informação dentro
 * dos requests, evitando aninhamento de objetos
 *
 * @param response AxiosResponse
 */
function getDataFromAxiosResponse(response: AxiosResponse): any {
  checkActionToNotify(response);
  return response.data;
}

function isStatusFromError(err: AxiosError, status: number): boolean | null {
  return err.response ? err.response.status === status : null;
}

function forceLogout(): void {
  Auth.authClearAll();
  window.location.reload();
}

function notifyError(text: string): void {
  Vue.notify({
    title: "Erro",
    type: "error",
    text
  });
}

// INTERCEPTOR [Request]
instance.interceptors.request.use((request: AxiosRequestConfig) => {
  putAuthorizarionOnHeader(request);
  return request;
});

/**
 * Responsável por verificar se o refresh já não foi solicitado antes
 * evitando que entre em loop caso tenha ou algum problema na api ou caso tenha sido
 * alterado a url da API enquanto se está usando o sistema.
 *
 * Ele dá um forceLogout(); pra que caso já tenha entrado ele deslogue do sistema, pois
 * sem isso se o primeiro refresh não funcionou ele vai entrar em loop tentando autenticar novamente.
 */
let enterRefreshAuth = false;

// INTERCEPTOR [Response]
instance.interceptors.response.use(
  getDataFromAxiosResponse,
  async (error: AxiosError) => {
    /**
     * Mostra uma mensagem de erro sempre que houver algum problema.
     */
    const errorMessage =
      error.response && error.response.data
        ? error.response.data.message
        : "Algum erro ocorreu, tente novamente mais tarde.";
    /**
     * Caso seja 401 ele automaticamente faz o refresh
     * e dispara um axios.request para refazer o request
     */
    if (error.config && isStatusFromError(error, 401)) {
      if (enterRefreshAuth) forceLogout();

      if (auth.refresh) {
        enterRefreshAuth = true;

        return UsuarioApi.refresh(auth.refresh)
          .then(() => {
            putAuthorizarionOnHeader(error.config);
            enterRefreshAuth = false;
            return axios.request(error.config).then(getDataFromAxiosResponse);
          })
          .catch(() => {
            notifyError("Problema ao re-autenticar");
            forceLogout();
          });
      } else {
        notifyError(errorMessage);
      }
    } else if (isStatusFromError(error, 500)) {
      return notifyError(
        "Algum problema ocorreu no servidor, espere um momento antes de tentar novamente"
      );
    } else {
      notifyError(errorMessage);
    }

    return Promise.reject(error);
  }
);

export default instance;
