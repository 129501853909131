import { TypePermissionEntity } from "@/data/default/types/enumPermission";
import { RouteConfig } from "vue-router";
import RouterDirector from "@/data/default/types/RouterDirector";

const builder = RouterDirector.makeRouterWithPermission({
  name: "sexo",
  permissionEntity: TypePermissionEntity.SEXO,
  componentRoot: () => import("./Index.vue"),
  componentList: () => import("./List.vue"),
  componentSave: () => import("./Save.vue")
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
