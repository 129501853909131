import { RouteConfig } from "vue-router";
import { TypePermissionEntity } from "@/data/default/types/enumPermission";
import RouterDirector from "@/data/default/types/RouterDirector";

const builder = RouterDirector.makeRouterWithPermission({
  name: "perfil",
  permissionEntity: TypePermissionEntity.PERFIL_USUARIO,
  componentRoot: () => import("./Index.vue"),
  redirectTo: null
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
