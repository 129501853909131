import { Component, Vue } from "vue-property-decorator";
import {
  TypePermissionEntity,
  TypePermissionAction
} from "@/data/default/types/enumPermission";
import auth from "@/utils/auth";
import { MetaPermission } from "@/data/default/types/metaPermission";

@Component
export default class Permission extends Vue {
  public permissionEntity: TypePermissionEntity | null = null;

  public $hasPermission(
    entity: TypePermissionEntity | string,
    action: TypePermissionAction | string
  ): boolean {
    return auth.hasPermission(entity, action);
  }

  // Verifica atravez dos meta dados de uma rota a permissão
  public $hasPermissionByMeta(metaPermission: MetaPermission): boolean {
    return this.$hasPermission(metaPermission.entity, metaPermission.action);
  }

  // Verifica exclusivamente se a entidade tem permissão de screen
  public $hasPermissionScreen(entity: TypePermissionEntity | string): boolean {
    return this.$hasPermission(entity, TypePermissionAction.SCREEN);
  }

  private has(action: TypePermissionAction | string): boolean {
    if (!this.permissionEntity) {
      return false;
    }
    return this.$hasPermission(this.permissionEntity, action);
  }

  //#region Permissões
  get canView(): boolean {
    return this.has(TypePermissionAction.VIEW);
  }

  get canEdit(): boolean {
    return this.has(TypePermissionAction.EDIT);
  }

  get canCreate(): boolean {
    return this.has(TypePermissionAction.CREATE);
  }

  get canDelete(): boolean {
    return this.has(TypePermissionAction.DELETE);
  }

  get canGetPendentes(): boolean {
    return this.has(TypePermissionAction.LIST_PENDING);
  }

  get canGetAprovado(): boolean {
    return this.has(TypePermissionAction.LIST_APPROVED);
  }

  get canGetRecusado(): boolean {
    return this.has(TypePermissionAction.LIST_DECLINED);
  }

  get canGetPreaprovado(): boolean {
    return this.has(TypePermissionAction.LIST_PRE_APPROVED);
  }

  get canGetAgendado(): boolean {
    return this.has(TypePermissionAction.LIST_SCHEDULED);
  }

  get canGetAtivo(): boolean {
    return this.has(TypePermissionAction.LIST_ACTIVE);
  }

  get canGetInativo(): boolean {
    return this.has(TypePermissionAction.LIST_INACTIVE);
  }

  get canGetConfirmado(): boolean {
    return this.has(TypePermissionAction.LIST_CONFIRMED);
  }

  get canGetRealizado(): boolean {
    return this.has(TypePermissionAction.LIST_PAST);
  }

  get canGetCancelado(): boolean {
    return this.has(TypePermissionAction.LIST_CANCELLED);
  }

  get canGetResolvido(): boolean {
    return this.has(TypePermissionAction.LIST_RESOLVED);
  }

  get canGetNaoResolvido(): boolean {
    return this.has(TypePermissionAction.LIST_UNRESOLVED);
  }

  get canGeArquivado(): boolean {
    return this.has(TypePermissionAction.LIST_ARCHIVED);
  }

  get canGetEmAndamento(): boolean {
    return this.has(TypePermissionAction.LIST_IN_PROGRESS);
  }

  get canGetFinalizada(): boolean {
    return this.has(TypePermissionAction.LIST_FINISHED);
  }

  get canRevoke(): boolean {
    return this.has(TypePermissionAction.REVOKE);
  }

  get canApprove(): boolean {
    return this.has(TypePermissionAction.APPROVE);
  }

  get canDecline(): boolean {
    return this.has(TypePermissionAction.DECLINE);
  }

  get canViewDetails(): boolean {
    return this.has(TypePermissionAction.VIEW_DETAILS);
  }

  get canListPagination(): boolean {
    return this.has(TypePermissionAction.LIST_PAGINATION);
  }

  get canListAll(): boolean {
    return this.has(TypePermissionAction.LIST_ALL);
  }

  get canUpload(): boolean {
    return this.has(TypePermissionAction.UPLOAD);
  }

  get canActiveProtectiveMensure(): boolean {
    return this.has(TypePermissionAction.ACTIVATE_PROTECTIVE_MEASURE);
  }

  get canDisableProtectiveMensure(): boolean {
    return this.has(TypePermissionAction.DISABLE_PROTECTIVE_MEASURE);
  }

  get canActivate(): boolean {
    return this.has(TypePermissionAction.ACTIVATE);
  }

  get canDisable(): boolean {
    return this.has(TypePermissionAction.DISABLE);
  }

  get canChangePassword(): boolean {
    return this.has(TypePermissionAction.CHANGE_PASSWORD);
  }

  get canSetStatus(): boolean {
    return this.has(TypePermissionAction.SET_STATUS);
  }

  get canListFridaForms(): boolean {
    return this.has(TypePermissionAction.LIST_FRIDA_FORM);
  }

  get canGrant(): boolean {
    return this.has(TypePermissionAction.GRANT);
  }

  get canDismiss(): boolean {
    return this.has(TypePermissionAction.DISMISS);
  }

  get canGenerateOperationalProgramming(): boolean {
    return this.has(TypePermissionAction.GENERATE_OPERATIONAL_PROGRAMMING);
  }

  get canForward(): boolean {
    return this.has(TypePermissionAction.FORWARD);
  }

  get canArchive(): boolean {
    return this.has(TypePermissionAction.ARCHIVE);
  }

  get canPublish(): boolean {
    return this.has(TypePermissionAction.PUBLISH);
  }

  get canRevokeToFix(): boolean {
    return this.has(TypePermissionAction.REVOKE_FIX);
  }
  //#endregion
}
