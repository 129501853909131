export function debounce(inner: any, ms = 0): (...args: any[]) => Promise<any> {
  let timer: number | undefined;
  let resolves: any[] = [];

  return (...args: any[]): Promise<any> => {
    // Run the function after a certain amount of time
    clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach(r => r(result));
      resolves = [];
    }, ms) as any;

    return new Promise(r => resolves.push(r));
  };
}

export function sanitizeBase64(base64: string): string {
  base64 = base64.split(",")[1];
  return base64;
}

export function isEmptyArray(array: Array<any>) {
  return Array.isArray(array) && array.length === 0;
}

export const __DEV__ = process.env.NODE_ENV === "development";

export function getOnlyPropWithValues(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) acc[key] = value;
    return acc;
  }, {});
}
