





















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ActionbarList extends Vue {
  @Prop({ required: true, type: Number }) public readonly currentPage!: number;
  @Prop({ required: true, type: Number }) public readonly totalPage!: number;
  @Prop({ required: true, type: Number }) public readonly maxPerPage!: number;
  @Prop({ required: false, default: true, type: Boolean })
  public readonly btnFilter!: boolean;
  @Prop({ required: false, default: true, type: Boolean })
  public readonly btnSave!: boolean;
  @Prop({ required: false, default: true, type: Boolean })
  public readonly btnRefresh!: boolean;
  @Prop({ required: false, default: true, type: Boolean })
  public readonly paginate!: boolean;

  public handlerPaginate(page: number) {
    this.$emit("paginate", page);
  }
}
