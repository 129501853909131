import { TypePermissionEntity } from "@/data/default/types/enumPermission";
import { RouteConfig } from "vue-router";
import RouterDirector from "@/data/default/types/RouterDirector";

const builder = RouterDirector.makeRouterWithPermission({
  name: "patrulhaMariaPenha",
  permissionEntity: TypePermissionEntity.PATRULHA_MARIA_PENHA,
  componentRoot: () => import("./Index.vue"),
  componentSave: () => import("./Save.vue"),
  redirectTo: "salvar"
});

export const Router: RouteConfig[] = [builder.getRootRoute()];

export const routes = builder.getRouteNames();
