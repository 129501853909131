<template>
  <div id="app">
    <notifications position="top bottom" class="mb-3 ml-2" />
    <router-view />
  </div>
</template>

<script>
import { provideRouter } from "./shared/compositions/useRouter";
import { provideBootstrap } from "./shared/compositions/useBootstrap";
import { provideStore } from "./shared/compositions/useStore";

export default {
  setup(_, context) {
    provideRouter(context);
    provideBootstrap(context);
    provideStore(context);
    return {};
  }
};
</script>
